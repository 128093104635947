export const formatPercentDoubleDigitPrecision = (x: number) =>
  x == null || isNaN(x) ? '' : `${Number((x * 100).toFixed(2))}%`;

export const formatNumber = (num?: number | null, options: Intl.NumberFormatOptions = { maximumFractionDigits: 2 }) =>
  num == null || isNaN(num) ? '' : new Intl.NumberFormat('en-US', { ...options }).format(num);

export const formatCurrency = (num?: number | null, options?: Intl.NumberFormatOptions) =>
  num == null || isNaN(num) ? '' : formatNumber(num, { style: 'currency', currency: 'USD', ...options });

export type Lbs = number | string | null;
export const formatLbs = (
  num?: Lbs,
  suffix: boolean = true,
  prefix: boolean = false,
  options?: Intl.NumberFormatOptions
) => {
  const _suffix = suffix ? ' lbs' : '';
  const _prefix = prefix && Number(num) > 0 ? '+' : '';
  return num == null
    ? ''
    : `${_prefix}${num.toLocaleString(undefined, { maximumFractionDigits: 2, ...options })}${_suffix}`;
};
