import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { FieldValues, useController } from 'react-hook-form';
import { ControlledNumberField } from './ControlledNumberField';
import { ControlledNumberFieldWithIncrementsProps } from './types';
import { stripNumber } from '@/components/helpers/number';
import { useCallback } from 'react';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  background: '#fff',
  border: `1px solid ${theme.palette.grey[400]}`,
}));
StyledIconButton.defaultProps = {
  size: 'small',
};

const LeftIconButton = styled(StyledIconButton)(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
  borderRight: 0,
}));

const RightIconButton = styled(StyledIconButton)(({ theme }) => ({
  borderRadius: ` 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  borderLeft: 0,
}));

export function ControlledNumberFieldWithIncrements<TFieldValues extends FieldValues = FieldValues>({
  disabled = false,
  increment = 1,
  onChange,
  allowNegative = false,
  ...numberFieldProps
}: ControlledNumberFieldWithIncrementsProps<TFieldValues>): JSX.Element {
  const { field } = useController({ name: numberFieldProps.name, control: numberFieldProps.control });
  const dataTestIdPrefix = numberFieldProps['data-testid'] ?? numberFieldProps.name;

  const onAddClick = useCallback(() => {
    const newValue = field.value + increment;
    field.onChange(newValue);
    onChange?.(newValue);
  }, [field, increment, onChange]);

  const onSubtractClick = useCallback(() => {
    let newValue = field.value - increment;
    if (!allowNegative && newValue < 0) newValue = 0;

    field.onChange(newValue);
    onChange?.(newValue);
  }, [allowNegative, field, increment, onChange]);

  const _onInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = stripNumber(event.target.value);
      field.onChange(newValue);
      onChange?.(newValue);
    },
    [field, onChange]
  );

  return (
    <Stack direction="row" data-testid={dataTestIdPrefix}>
      <LeftIconButton
        data-testid={`${dataTestIdPrefix}-subtract-button`}
        disabled={disabled || (!allowNegative && field.value <= 0)}
        onClick={onSubtractClick}
      >
        <RemoveIcon />
      </LeftIconButton>
      <ControlledNumberField
        {...numberFieldProps}
        data-testid={`${dataTestIdPrefix}-number-field`}
        disabled={disabled}
        onInput={_onInput}
        allowNegative={allowNegative}
        sx={{
          '.MuiInputBase-root': { borderRadius: 0, height: '100%' },
          '.MuiInputBase-input': { textAlign: 'center' },
        }}
      />
      <RightIconButton data-testid={`${dataTestIdPrefix}-add-button`} disabled={disabled} onClick={onAddClick}>
        <AddIcon />
      </RightIconButton>
    </Stack>
  );
}
